import React, { useState, useEffect } from 'react'
import { cloudredApplications } from 'store/cloudred.js'
import TextInput from 'components/TextInput/TextInput.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import KVTags from 'components/KVTags/KVTags.js'
import ToggleInput from 'components/Toggle/ToggleInput.js'
import validate from './BasicInfo.validate.js'

function sanitizeName(text) {
  return text
    .toLowerCase()
    .replace(/[ \\/|]/gi, '-')
    .replace(/[^0-9a-z-_]/gi, '')
    .replace(/^[-_]/, '')
    .substring(0, 20)
}

export default ({
  cluster_name,
  tagguid,
  labels,
  sandbox,
  nsp_sources,
  onChange,
  validation,
  region,
  disabled,
  setBasicLoading = () => {},
}) => {
  const [applications, setApplications] = useState([])
  const [lastChar, setLastChar] = useState('')

  useEffect(() => {
    setBasicLoading(true)
    cloudredApplications()
      .then(data => {
        setApplications(
          data
            .map(app => ({
              label: app.name,
              value: app.key,
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))
        )
      })
      .catch(error => console.error(error))
      .finally(() => setBasicLoading(false))
  }, [])

  // if(clusterToImport.cluster_name) {
  //   cluster_name = clusterToImport.cluster_name + '-2';
  // }

  return (
    <div className='create-form-section'>
      <h3 className='create-form-section-title'>BASIC INFO</h3>

      <div className='create-form-section-inner'>
        <TextInput
          required
          label='CLUSTER NAME'
          detail='Choose a name for your cluster. The name must be unique across all clusters in the current region. '
          value={cluster_name}
          tooltipText='Name must be between 5 and 20 and only contain alphanumeric characters or hyphens.'
          onChange={e => {
            let name = sanitizeName(e.target.value)
            setLastChar(e.target.value.replace(/[0-9a-z-_]/gi, ''))
            return onChange(
              'cluster_name',
              name,
              validate('cluster_name', name)
            )
          }}
          error={validation.cluster_name && validation.cluster_name[0]}
          aside={
            <p>
              {lastChar && <span>{lastChar}</span>} {20 - cluster_name.length}
            </p>
          }
          disabled={disabled}
        />

        <SelectInput
          required
          label='CLOUDRED TAGGUID'
          detail={
            <span>
              Specify the application registered in{' '}
              <a
                href='https://cloudred.nike.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                CloudRed
              </a>{' '}
              to have the necessary metadata automatically associated with your
              cluster.
            </span>
          }
          options={applications}
          onChange={tag =>
            onChange('tagguid', tag.value, validate('tagguid', tag.value))
          }
          value={applications.find(app => app.value === tagguid)}
          tooltipText='Any email address associated with this account will receive cluster-related notifications.'
          className='tagguid-field'
          formatOptionLabel={({ value, label }) => (
            <div className='tagguid-option'>
              <p className='tagguid-option-label'>{label}</p>
              <p className='tagguid-option-value'>{value}</p>
            </div>
          )}
          error={validation.tagguid && validation.tagguid[0]}
          disabled={disabled}
        />

        <SelectInput
          label='REGION'
          detail='Select the region in which this cluster will be deployed To change the region, please use the region switcher in the top-right corner.'
          options={[region]}
          value={region}
          disabled
        />

        <ToggleInput
          label='SANDBOX'
          detail='Optionally enable use of a playground MAP cluster. This will decrease provisioning time, and it will cut unnecessary costs. However, it is not recommended for QA/Production Environments.'
          value={sandbox}
          onClick={() => onChange('sandbox', !sandbox, false)}
          disabled={disabled}
        />

        {region.value === 'cn-northwest-1' || region.value === 'cn-north-1' ? (
          ''
        ) : (
          <ToggleInput
            label='NSP Sources'
            detail='Enable/Disable NSP Sources for Metrics/Logs.'
            disabled={disabled}
            tooltipText='NSP Integration'
            value={nsp_sources}
            onClick={() => onChange('nsp_sources', !nsp_sources, false)}
          />
        )}

        <KVTags
          required
          label='LABELS'
          detail='Apply labels to your clusters to organize and identify them. The `env` label is required to distinguish cluster environments (prod, qa, dev, etc.).'
          tags={labels}
          error={validation.labels}
          onChange={tags => onChange('labels', tags, validate('labels', tags))}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
