import validate from 'validate.js'

const validateIAMRoleARN = value => {
  const presenceValidation = validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: "AWS IAM ARN can't be blank",
      },
    },
  );
  if (presenceValidation) return presenceValidation;

  const roleNameMatch = value.match(/^arn:aws(?:-cn|):iam::\d{12}:role\/(.+)$/);
  if (!roleNameMatch) {
    return ['ARN must match the format "arn:aws:iam::123456789012:role/role-name"'];
  }

  const roleName = roleNameMatch[1];

  // Validate the role name part
  const roleNameValidation = validate.single(
    roleName,
    {
      format: {
        pattern: /^[^\W_][\w-]{5,}[^\W_]$/,
        message: 'Invalid role name',
      },
    },
    { fullMessages: false }
  );

  if (roleNameValidation) return roleNameValidation;

}

const validateImage = value =>
  value &&
  value.length > 0 &&
  validate.single(
    value,
    {
      format: {
        pattern: /^.*(amazonaws\.com|artifactory).*$/,
        message: 'Image must be hosted by amazonaws.com or Nike-artifactory',
      },
    },
    { fullMessages: false }
  )

export default (key, value) =>
  ({
    iam_role_arn: validateIAMRoleARN,
    image: validateImage,
  }[key](value))
