import validate from 'validate.js'

const globalOpts = {
  fullMessages: false,
}

const validateClusterName = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: "Cluster Name can't be blank",
      },
      format: {
        pattern: /^[a-z0-9-_]*[^\W_]$/,
        message:
          'Invalid cluster name',
      },
      length: {
        minimum: 5,
        maximum: 20,
        message: 'Cluster Name must be 5-20 characters long',
      },
    },
    globalOpts
  )

const validateTagguid = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: "TAGGUID can't be blank",
      },
    },
    globalOpts
  )

const validateLabels = value =>
  ((value.length === 0 ||
    value.filter(label => label.key?.includes('env')).length === 0) &&
    'At least one label with key `env` is required') ||
  (value.filter(label => !label.key || !label.value).length > 0 &&
    'All labels must have both a key and a value')

export default (key, value) =>
  ({
    cluster_name: validateClusterName,
    tagguid: validateTagguid,
    labels: validateLabels,
  }[key](value))